<template>
  <div class="orderPayOnlineList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="缴费单号" v-model="searchParams.serialNumber"></v-input>
        <v-select label="缴费途径" v-model="searchParams.optSource" :options="optSourceOps"></v-select>
        <v-select label="付款方式" v-model="searchParams.moneyType" :options="moneyTypeOps"></v-select>
        <v-select label="订单状态" v-model="searchParams.orderStatus" :options="orderStatusOps"></v-select>
        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-datepicker label="缴费时间段" :startTime.sync="searchParams.fromTime" :endTime.sync="searchParams.toTime" type="rangedatetimer"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="view" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.orderStatus === 2 || scope.row.orderStatus === 4" text="再次销账" type="text" permission="update" @click="cancelAgain(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  retryChargeOffURL
} from './api'
import {
  optSourceOps,
  optSourceMap,
  moneyTypeOps,
  moneyTypeMap,
  orderStatusOps,
  orderStatusMap
} from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'OrderPayOnlineList',
  data () {
    return {
      optSourceOps,
      moneyTypeOps,
      orderStatusOps,
      communityParams,
      tenantParams,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        serialNumber: '',
        optSource: undefined,
        moneyType: undefined,
        orderStatus: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        communityId: '',
        regionId: '',
        fromTime: '',
        toTime: ''
      },
      headers: [
        {
          prop: 'serialNumber',
          label: '缴费单号'
        },
        {
          prop: 'orderMoney',
          label: '应缴金额',
          align: 'right'
        },
        {
          prop: 'orderMoneyReal',
          label: '实付金额',
          align: 'right'
        },
        {
          prop: 'optSourceText',
          label: '缴费途径',
          formatter (row) {
            return optSourceMap[row.optSource]
          }
        },
        {
          prop: 'payTypeText',
          label: '付款方式',
          formatter (row) {
            return moneyTypeMap[row.payType]
          }
        },
        {
          prop: 'payUserAddress',
          label: '业主房号'
        },
        {
          prop: 'payUserName',
          label: '付款联系人'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'paidTime',
          label: '缴费时间'
        },
        {
          prop: 'orderStatusText',
          label: '订单状态',
          formatter (row) {
            return orderStatusMap[row.orderStatus]
          }
        }
      ],
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      }
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'orderPayOnlineForm',
        query: {
          id: row.id
        }
      })
    },
    async cancelAgain (row) {
      let isOk = await this.$confirm('是否确认销账')
      isOk && this.$axios.post(retryChargeOffURL, this.$qs.stringify({
        estatesOrderId: row.id
      }), this.postHeader).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>
